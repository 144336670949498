import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Buttons from "../../components/Buttons"
import * as Components from "./components"

import { config, Translater } from "../../config"
import { useHttp } from "../../hooks/http.hook"
import { useHistory } from "react-router-dom"
import { TCreateValidationSchema, IForm } from "./types"
import { useValidation } from "../../hooks"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [isValid, toggleValid] = useState({
    sum: false,
  })

  const [form, setForm] = useState<IForm>({
    user: null,
    sum: "",
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const [isClicked, setClicked] = useState(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      sum: {
        condition: +form.sum > 0,
        error: `
        ${Translater.ErrorSize[language.slug]}:
        ${Translater.TableTitles.sum[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    createHandler: async () => {
      try {
        setClicked(true)
        await validation()

        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(errors.length > 0)
        console.log(e)
      }
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
        const data = form.user
          ? { sum: +form.sum, user: form.user }
          : { sum: +form.sum }

        await request(`${config.API}/referals`, "POST", data, {
          Authorization: `Bearer ${token as string}`,
        })

        history.goBack()
      } catch (e) {}
    },
  }

  useEffect(() => {
    toggleValid({ ...isValid, sum: +form.sum > 0 })
  }, [form.sum])

  return (
    <Page.Wrapper title={Translater.BookingCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.ReferalsCreatePage.title[language.slug]}
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        form={form}
        isValid={validationSchema}
        setForm={setForm}
        isClicked={isClicked}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
