import React, { useState, useEffect, useContext, createContext } from "react"

import {
  WSState,
  IWSContext,
  WSMessageData,
  WSContextActions,
} from "../types/contexts/ws"

import * as Contexts from "../contexts"
import { config } from "../config"
import { TMessage } from "../types/items"
import { io } from "socket.io-client"

const WSContext = createContext<IWSContext>({
  ws: null,
  messages: [],
  isConnected: false,
  actions: {
    DISCONNECT: () => {},
    RECONNECT: () => {},
  },
})

export default WSContext

export const WSContextProvider: React.FC = ({ children }) => {
  const { token } = useContext(Contexts.AuthContext)

  const [ws, updateWS] = useState<WSState | null>(null)
  const [messages, setMessages] = useState<TMessage[]>([])
  const [isConnected, toggleConnected] = useState<boolean>(false)

  const socket = io("wss://production-api.laspezia.kitg.com.ua:6080", {
    autoConnect: true,
    forceNew: true,
    reconnection: true,
    extraHeaders: {
      Authorization: `Bearer ${token}`,
      "x-role": "admin",
    },
  })

  const actions: WSContextActions = {
    DISCONNECT: () => {
      ws?.websocket?.close()
    },
    RECONNECT: () => {
      ws?.websocket?.close()
      updateWS((prev) => ({
        _increment: prev?._increment || 0,
        websocket: new WebSocket(config.ws),
      }))
    },
  }

  const handleOnMessage = {
    switch: (data: WSMessageData | null) => {
      switch (data?.type) {
        case "ORDER":
          break
        case "USER":
          break
        default:
          break
      }
    },
  }
  const _actions = {
    sendToken: (token: string, isUseEffect: boolean) => {
      let wsRequestData = JSON.stringify({
        type: "admin",
        token,
      })

      if (ws?.websocket.readyState) ws?.websocket.send(wsRequestData)
    },
    onOpen: () => {
      if (token) {
        _actions.sendToken(token, false)
      }
      toggleConnected(true)
    },
    onMessage: (event: MessageEvent) => {
      event.data &&
        handleOnMessage.switch(JSON.parse(event.data) as WSMessageData)
      setMessages((prev) => {
        return [...prev, JSON.parse(event.data)]
      })
    },
    onError: (event: Event) => {
      toggleConnected(false)
      setTimeout(actions.RECONNECT, 5000)
    },
    onClose: (event: CloseEvent) => {
      updateWS(null)
      toggleConnected(false)
      setTimeout(actions.RECONNECT, 5000)
    },
  }

  useEffect(() => {
    socket.on("new_order", (data) => {
      if (data) {
        setMessages([
          {
            action: "STATUS_CHANGE",
            type: "ODER",
            slug: "new_order",
          },
        ])
      }
    })
  }, [socket])

  return (
    <WSContext.Provider
      value={{
        ws,
        isConnected,
        actions,
        messages,
      }}
    >
      {children}
    </WSContext.Provider>
  )
}
