import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  KeyboardEvent,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Components from "./components"
import { useHttp } from "../../hooks"

import { config, Translater } from "../../config"

import {
  IPagination,
  IFilter,
  TSortBy,
  IProduct,
  IRestaurant,
  ICategory,
} from "../../types/items"
import { SearchSelect } from "../../components/Page"

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, totalCount, request } = useHttp()

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "updatedAt",
    sortOrder: "desc",
  })
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([])
  const [searchRest, setSearchRest] = useState<string>("")
  const [searchCategory, setSearchCategory] = useState<string>("")
  const [searchValue, setSearchValue] = useState<string>("")
  const [categories, setCategories] = useState<ICategory[]>([])

  const [primary, setPrimary] = useState<IProduct[]>([])

  const Utils = {
    findCategoryById: () => {
      const elem = categories.find((item) => item._id === searchCategory)
      if (elem) {
        const result = elem?.title
        return result
      }

      return ""
    },
    findTitleRestById: () => {
      const elem = restaurants.find((item) => item._id === searchRest)

      if (elem) {
        const result = elem?.title
        return result
      } else {
        return searchRest[0]
      }
    },
  }

  const Events = {
    searchHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value)
    },
    pressHandler: (e: KeyboardEvent) => {
      if (e.key === "Enter") Callbacks.Fetch()
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
    sortHandler: (name: TSortBy) => {
      let sortOrder: "asc" | "desc"
      if (filter.sortOrder === "asc") sortOrder = "desc"
      else sortOrder = "asc"
      setFilter({
        sortBy: name,
        sortOrder,
      })

      setPagination({ ...pagination, page: 1 })
    },
    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page })
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    },
    optionHandler: (option: ICategory) => {
      option._id && setSearchCategory(option._id)
    },
    optionRestaurantHandler: (option: IRestaurant) => {
      option._id && setSearchRest(option._id)
      console.log("rest option", option.title + " " + option._id)
      Callbacks.FetchProductsByCategory()
    },
    clearFilterHandler: () => {
      setSearchCategory("")
      setSearchRest("")
    },
  }

  const Callbacks = {
    FetchProductsByCategory: async () => {
      try {
        if (searchCategory) {
          const response: IProduct[] = await request(
            `${config.API}/products/${searchCategory}/byCategory?full=1&page=${pagination.page}&perPage=${pagination.perPage}
          &name=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            },
            true
          )

          response && setPrimary(response)
        }
      } catch (e) {
        console.log("E", e)
      }
    },
    FetchCategories: async () => {
      try {
        const response: ICategory[] = await request(
          `${config.API}/categories/?perPage=50`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          searchCategory ? true : false
        )

        response && setCategories(response)
      } catch (e) {
        console.log("E", e)
      }
    },
    Fetch: async () => {
      try {
        function createUrl() {
          const url = `${config.API}/products/${searchRest}/byRestaurant`

          return url
        }

        if (searchRest.length === 0) {
          const response: IProduct[] = await request(
            `${config.API}/products?full=1&page=${pagination.page}&perPage=${pagination.perPage}
            &name=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            },
            true
          )
          if (response) {
            setPrimary(response)
          }
        } else {
          const response: IProduct[] = await request(
            createUrl() +
              `?page=${pagination.page}&perPage=${pagination.perPage}&children=${pagination.children}&name=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            },
            true
          )
          console.log("RESPONSE", response)
          if (response) {
            setPrimary(response)
          }
        }
      } catch (e) {}
    },
    FetchRestaurants: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants/`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )
        if (response) {
          setRestaurants(response)
        }
      } catch (e) {}
    },
  }

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  useEffect(() => {
    Callbacks.FetchRestaurants()
    Callbacks.FetchCategories()
  }, [])

  useEffect(() => {
    searchCategory ? Callbacks.FetchProductsByCategory() : Callbacks.Fetch()
    if (!primary.length) {
      setPagination({
        page: 1,
        perPage: 10,
        totalCount: 0,
      })
    }
  }, [
    searchCategory,
    searchRest,
    pagination.page,
    pagination.perPage,
    filter.sortBy,
    filter.sortOrder,
  ])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper title={Translater.ProductsPage.title[language.slug]}>
      <Page.Header
        title={Translater.ProductsPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.products?.change}
        linkTo="/products/create"
      />

      <div className="filter-container">
        <SearchSelect
          height={47}
          width={290}
          data={restaurants}
          placeholder={Translater.CategoriesPage.pickRestaurant[language.slug]}
          value={searchRest ? searchRest : null}
          searchField="title"
          defaultValue={Utils.findTitleRestById()}
          setSwitch={Events.optionRestaurantHandler}
        />

        <SearchSelect
          height={47}
          width={260}
          data={categories}
          placeholder={Translater.Placeholders.filterByCategory[language.slug]}
          value={searchCategory ? searchCategory : null}
          searchField="title"
          setSwitch={Events.optionHandler}
        />

        <button
          className="btn btn--default btn--default--green clear-filter"
          onClick={Events.clearFilterHandler}
        >
          {Translater.Items.Clear[language.slug]}
        </button>

        <Page.SortBar
          type="product"
          searchBar
          filter={filter}
          value={searchValue}
          perPage={pagination.perPage}
          pressHandler={Events.pressHandler}
          inputHandler={Events.searchHandler}
          selectHandler={Events.selectHandler}
        />
      </div>

      <Components.Table
        page={pagination.page}
        items={primary}
        sortHandler={Events.sortHandler}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>
        <Page.Pagination
          linkTo="/products"
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          setPagination={setPagination}
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default IndexPage
