import React, { Fragment, useContext, useEffect, useState } from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import * as Buttons from '../../../../components/Buttons'

import { Translater, config } from '../../../../config'

import { IDetailBlockProps } from './types'
import { IProduct } from '../../../../types/items'
import { useHttp } from '../../../../hooks'

const DetailBlock: React.FC<IDetailBlockProps> = ({ data }) => {
  const { language } = useContext(Contexts.LanguageContext)
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()

  const [products, setProducts] = useState<IProduct[]>([])

  const fetchProduct = async (_id: string) => {
    try {
      const response: IProduct = await request(
        `${config.API}/products/${_id}`,
        'GET',
        null,
        {
          Authorization: `Bearer ${token as string}`,
        }
      )
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const fetchProducts = async () => {
    try {
      if (!data?.favourites) {
        return
      }

      const requests = data?.favourites?.map((item) => fetchProduct(item))
      //@ts-ignore
      const response: IProduct[] = await Promise.all<IProduct[]>(requests)

      if (response) {
        setProducts(response)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchProducts()
  }, [data?.favourites])

  return (
    <Form.LabelField
      fontSize='small'
      label={Translater.TableTitles.favourites[language.slug]}
      text={
        !data.favourites.length
          ? Translater.TableTitles.null[language.slug]
          : ''
      }
    >
      {products?.map((child, index) => (
        <Fragment key={child?._id}>
          <Buttons.Link title={child?.name} linkTo={`/products/${child._id}`} />

          {index + 1 !== data.favourites?.length ? (
            <span className='comma'>,</span>
          ) : null}
        </Fragment>
      ))}
    </Form.LabelField>
  )
}

export default DetailBlock
