import React, { useState, useContext, ChangeEvent, useMemo } from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Buttons from "../../components/Buttons"
import * as Components from "./components"

import { config, Translater } from "../../config"
import { useHttp } from "../../hooks"
import { useHistory } from "react-router-dom"
import { IRole } from "../../types/items"
import { TMutliLangTitles } from "../../types/multiLang"
import { TCreateValidationSchema } from "./types"
import { useValidation } from "../../hooks"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()

  const [form, setForm] = useState<IRole>({
    title: "",
    rules: {
      orders: {
        view: false,
        change: false,
        delete: false,
      },
      categories: {
        view: false,
        change: false,
        delete: false,
      },
      products: {
        view: false,
        change: false,
        delete: false,
      },
      additions: {
        view: false,
        change: false,
        delete: false,
      },
      booking: {
        view: false,
        change: false,
        delete: false,
      },
      restaurants: {
        view: false,
        change: false,
        delete: false,
      },
      users: {
        view: false,
        change: false,
        delete: false,
      },
      staff: {
        view: false,
        change: false,
        delete: false,
      },
      roles: {
        view: false,
        change: false,
        delete: false,
      },
      ordersHistory: {
        view: false,
        change: false,
        delete: false,
      },
      stocks: {
        view: false,
        change: false,
        delete: false,
      },
      banner: {
        view: false,
        change: false,
        delete: false,
      },
      seo: {
        view: false,
        change: false,
        delete: false,
      },
      settings: {
        view: false,
        change: false,
        delete: false,
      },
      landings: {
        view: false,
        change: false,
        delete: false,
      },
      delivery: {
        view: false,
        change: false,
        delete: false,
      },
    },
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      title: {
        condition: true,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.title[language.slug]}`,
      },
    }),
    [form, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      setForm({ ...form, title: value })
    },
    createHandler: async () => {
      try {
        await validation()

        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log(e)
      }
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
        const response = await request(
          `${config.API}/roles`,
          "POST",
          { ...form },
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          history.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <Page.Wrapper title={Translater.RoleCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.RoleCreatePage.title[language.slug]}
        backButtonLink="/roles"
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        form={form}
        isValid={validationSchema}
        setForm={setForm as any}
        inputHandler={Events.inputHandler}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
