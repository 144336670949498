import React, { useState, useContext, useEffect } from "react"
import { ICategory } from "../../types/items"

import { useHttp } from "../../hooks/http.hook"
import * as Contexts from "../../contexts"

import { config, Translater } from "../../config"
import "./stocks.scss"

const ListCategories: React.FC<{
  categoriesList: string
  selectHandler: (e: any) => void
  restaurant: string
}> = ({ selectHandler, categoriesList, restaurant }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()
  const [primary, setPrimary] = useState<ICategory[]>([])
  const { language } = useContext(Contexts.LanguageContext)

  let res
  const Callbacks = {
    Fetch: async () => {
      try {
        const response: ICategory[] = await request(
          `${config.API}/categories/${restaurant}/byRestaurant?perPage=100`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          setPrimary(response)
        }
        res = response
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [restaurant])

  return (
    <select
      name="categoriesList"
      value={categoriesList}
      onChange={selectHandler}
      onClick={selectHandler}
      className="input-list"
    >
      <option value="" disabled>
        {Translater.Placeholders.chooseCategory[language.slug]}
      </option>
      {primary.map(({ _id, title }) => {
        return (
          <option key={_id} value={_id}>
            {title == undefined ? "" : title}
          </option>
        )
      })}
    </select>
  )
}

export default ListCategories
