import React, { Fragment, useContext, useEffect, useState } from "react"

import * as Contexts from "../../../contexts"
import * as Form from "../../../components/Form"
import * as Page from "../../../components/Page"

import { config, Translater } from "../../../config"
import { useHttp } from "../../../hooks"

import { IStock, IOption, IRestaurant, IProduct } from "../../../types/items"
import { IConfigBlockProps } from "./types"
import ListRestaurants from "../ListRestaurants"
import ListCategories from "../ListCategories"
import Select from "react-select"
import { Checkbox } from "@material-ui/core"

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  from,
  multiLangValues,
  setParentID,
  inputHandler,
  setProducts,
  selectHandler,
  setForm,
  setInfiniteStock,
  infiniteStock,
  isClicked,
}) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [products, setProductsOption] = useState<IProduct[]>([])
  const [val, setVal] = useState<{ lable: string; value: string }[]>()
  const [productsTitle, setProductsTitle] = useState<IProduct[]>([])

  const Events = {
    loadProductsOptions: async () => {
      try {
        const response: IProduct[] = await request(
          `${config.API}/products/${form.categoriesList}/byCategory?perPage=100`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        console.log("response", response)

        if (response && form._id) {
          setForm({
            ...form,
            products: [response[0]._id],
          })
        }
        setProductsOption(response)
      } catch (e) {}
    },
    FetchRestaurants: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          setForm({
            ...form,
            restaurantsList: response.map((element) => element._id)[0],
          })
        }
      } catch (e) {}
    },
    FetchProduct: async (productId: string) => {
      try {
        const response: IProduct = await request(
          `${config.API}/products/${productId}/`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        return response
      } catch (e) {}
    },
    loadOptions: async () => {
      try {
        const response: IStock[] = await request(
          `${config.API}/stocks/all`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        const formatted: IOption[] = []
        if (response.length) {
          for (const item of response) {
            const option = {
              value: item._id as string,
              label: item.name as string,
            }
            formatted.push(option)
          }
        }
        return formatted
      } catch (e) {}
    },
    optionHandler: (option: any) => {
      setParentID(option.value)
    },
    optionProductsHandler: (option: any) => {
      setVal(option)
      setProducts && setProducts(option)
      setForm({
        ...form,
        productsList: val?.map((item) => item.value),
      })
    },
  }

  useEffect(() => {
    from === "create" && Events.FetchRestaurants()
  }, [])

  useEffect(() => {
    Events.loadProductsOptions()
  }, [form.categoriesList])

  useEffect(() => {
    setForm({
      ...form,
      productsList: val?.map((item) => item.value),
    })
  }, [val])

  useEffect(() => {
    const promises = []
    for (let id in form.productsList as unknown as string[]) {
      promises.push(Events.FetchProduct(form.productsList[id]))
    }
    Promise.all(promises).then((res) => setProductsTitle(res as IProduct[]))
  }, [form.productsList])

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          name="name"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.name.length >= 2 : true}
          value={form.name}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.description[language.slug]}
      >
        <Form.Input
          name="description"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.description.length >= 2 : true}
          value={form.description}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.percent[language.slug]}
      >
        <Form.Input
          type="number"
          name="percent"
          value={form.percent}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <Form.Input
          type="file"
          name="photo"
          isValid={isClicked ? !!form.photo : true}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      {from !== "create" && (
        <img alt="Stock" src={`${config.imageProxy}${form.photo}`} />
      )}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.infiniteStock[language.slug]}
      >
        <Checkbox
          style={{ color: "#B6BABF" }}
          checked={form.infinite}
          onChange={() => {
            setForm((props: IStock) => ({ ...props, infinite: !form.infinite }))
          }}
        />
      </Form.LabelField>

      {!form.infinite ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.dateStart[language.slug]}
        >
          <Form.InputDate
            value={form.dateStart}
            max={form.dateEnd as string}
            type="number"
            name="dateStart"
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}

      {!form.infinite ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.dateEnd[language.slug]}
        >
          <Form.InputDate
            value={form.dateEnd}
            min={form.dateStart as string}
            type="number"
            name="dateEnd"
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.restaurantsList[language.slug]}
      >
        <ListRestaurants
          selectHandler={selectHandler}
          restaurantsList={form.restaurantsList}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.categoriesList[language.slug]}
      >
        <ListCategories
          restaurant={
            Array.isArray(form?.restaurantsList)
              ? form.restaurantsList[0]
              : form.restaurantsList
          }
          selectHandler={selectHandler}
          categoriesList={form.categoriesList}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.productsList[language.slug]}
      >
        <Fragment>
          <Select
            isMulti={true}
            isSearchable
            defaultValue={
              products.map((item) => ({
                name: item.name,
                value: item._id,
              }))[0]
            }
            styles={{
              control: () => ({
                display: "flex",
                border: "1px solid #e6e6e6",
                borderRadius: 5,
                outline: 0,
                cursor: "pointer",
              }),
              input: () => ({
                fontSize: 16,
              }),
              valueContainer: () => ({
                overflowY: "auto",
                width: "320px",
              }),
            }}
            options={
              products.map((item) => ({
                label: item.name,
                value: item._id,
              })) as any[]
            }
            closeMenuOnSelect={false}
            placeholder={Translater.Placeholders.chooseProduct[language.slug]}
            noOptionsMessage={() =>
              Translater.Placeholders.noOptionsMessage[language.slug]
            }
            value={
              val ||
              productsTitle.map((item) => ({
                label: item.name,
                value: item._id as string,
              }))
            }
            onChange={Events.optionProductsHandler}
            className={"invalid"}
          />
        </Fragment>
      </Form.LabelField>
    </Fragment>
  )
}

export default ConfigBlock
