import React, { useState, useEffect, useContext, ChangeEvent } from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"

import { useHttp } from "../../hooks"

import { config, Translater } from "../../config"

import { ICategory, IFilter, IPagination } from "../../types/items"
import * as Form from "../../components/Form"
import * as Components from "./components"

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request, totalCount } = useHttp()

  const [primary, setPrimary] = useState<any>([])
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "updatedAt",
    sortOrder: "desc",
  })

  const Events = {
    reloadHandler: () => {
      Callbacks.Fetch()
    },

    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page })
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: any = await request(
          `${config.API}/referals?page=${pagination.page}&perPage=${pagination.perPage}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [pagination.perPage, pagination.page])

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper title={Translater.ReferalsPage.title[language.slug]}>
      <Page.Header
        title={Translater.ReferalsPage.title[language.slug]}
        enableButton={true}
        linkTitle={Translater.Buttons.create[language.slug]}
        linkTo="/referals/create"
      />

      <Page.SortBar
        filter={filter}
        perPage={pagination.perPage}
        selectHandler={Events.selectHandler}
      />

      <Components.Table items={primary} reloadHandler={Events.reloadHandler} />

      <Page.Footer>
        <Page.Pagination
          pagination={pagination}
          setPagination={setPagination}
          paginateHandler={Events.paginateHandler}
          linkTo="/referals"
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default IndexPage
