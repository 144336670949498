import { ITableTitle } from "../../../../types/table"

export const STitles: ITableTitle[] = [
  { type: "header", slug: "code" },
  { type: "header", slug: "sum" },
  { type: "header", slug: "updatedAt" },
  { type: "header", slug: "createdAt" },
  { type: "header", slug: "user" },
  { type: "header", slug: "used" },
  { type: "header", slug: "" },
]
