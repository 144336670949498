import React, { useState, useContext, ChangeEvent, useMemo } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import { useHistory } from "react-router-dom"
import moment from "moment"

import * as Components from "./components"
import * as Contexts from "../../contexts"
import * as Buttons from "../../components/Buttons"
import * as Page from "../../components/Page"

import { SInitialForm } from "./static"
import { TCoordinates, TForm } from "./types"
import { config, Translater } from "../../config"
import { IRestaurant } from "../../types/items"
import { useHttp } from "../../hooks"
import { useValidation } from "../../hooks"

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  const { loading, request } = useHttp()
  const [photo, setPhoto] = useState<File | null>(null)
  const [headerPhoto, setHeaderPhoto] = useState<File | null>(null)
  const [form, setForm] = useState<TForm>(SInitialForm)

  const [value, setValue] = useState<string>("")
  const [startDate, setStartDate] = useState<string | Date>(new Date())
  const [endDate, setEndDate] = useState<Date | string>(new Date())
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const [coordinates, setCoordinates] = useState<TCoordinates>({
    lat: "0",
    lon: "0",
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<any>(
    () => ({
      title: {
        condition: form.title.length >= 3,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      photo: {
        condition: photo !== null,
        error: `
        ${Translater.ErrorPhoto[language.slug]}:
        ${Translater.TableTitles.phone[language.slug]}`,
      },
      phone: {
        condition: !!form.phone,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.phone[language.slug]}`,
      },
      email: {
        condition: !!form.email,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.email[language.slug]}`,
      },
      value: {
        condition: !!value,
        error: `
          ${Translater.ErrorAddress[language.slug]}:
          ${Translater.TableTitles.address[language.slug]}`,
      },
      startDate: {
        condition: startDate <= endDate,
        error: `${Translater.ErrorTimeStart[language.slug]}`,
      },
      endDate: {
        condition: startDate < endDate,
        error: `${Translater.ErrorTimeEnd[language.slug]}`,
      },
    }),
    [form, language, value, photo, startDate, endDate]
  )
  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "photo") {
        setPhoto(e.target?.files?.length ? e.target?.files[0] : null)
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    inputFileHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setHeaderPhoto(e.target?.files?.length ? e.target?.files[0] : null)
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: async () => {
      try {
        setIsClicked(true)
        await validation()
        await Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
        try {
          await validation()
          await Callbacks.Create()
        } catch (e) {
          toogleIsAlertOpen(true)
        }
      }
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
        const {
          title,
          password,
          login,
          email,
          phone,
          facebook,
          telegram,
          instagram,
        } = form

        const { lon, lat } = coordinates

        const contacts: IRestaurant["contacts"] = {
          phone,
          email,
          social: {
            facebook,
            instagram,
            telegram,
          },
          address: {
            longitude: lon,
            latitude: lat,
            label: value,
          },
        }

        const data: FormData = new FormData()

        data.append("contacts", JSON.stringify(contacts))
        data.append(
          "workingTime",
          JSON.stringify({
            start: moment(startDate).format("HH:mm"),
            end: moment(endDate).format("HH:mm"),
          })
        )

        if (photo) data.append("restPhoto", photo)

        headerPhoto && data.append("headerPhoto", headerPhoto)
        data.append("title", title)
        data.append("headerColor", form.headerColor)
        if (password) data.append("password", password)
        if (login) data.append("login", login)

        const response = await request(
          `${config.API}/restaurants`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response.title) {
          history.goBack()
        }
      } catch (e) {
        toogleIsAlertOpen(true)
      }
    },
  }

  return (
    <Page.Wrapper title={Translater.RestaurantsCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.RestaurantsCreatePage.title[language.slug]}
        backButtonLink="/restaurants"
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <Components.ConfigBlock
        isCreate
        value={value}
        form={form}
        photo={photo}
        isClicked={isClicked}
        headerPhoto={headerPhoto}
        inputHandler={Events.inputHandler}
        setForm={setForm}
        inputFileHandler={Events.inputFileHandler}
      />

      <Components.ContactsBlock
        value={value}
        form={form}
        setValue={setValue}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        isClicked={isClicked}
        setEndDate={setEndDate}
        setCoordinates={setCoordinates}
        isValid={validationSchema}
        inputHandler={Events.inputHandler}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {[...errors].map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
