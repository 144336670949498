import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from 'react'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import { useHttp } from '../../hooks'
import { config, Translater } from '../../config'
import * as Form from '../../components/Form'
import { IDelivery } from './types'
import { useValidation } from '../../hooks'
import ListRestaurants from './ListRestaurants'

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { loading, request } = useHttp()

  const [percents, setPercents] = useState<number>(0)
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const DefaultIForm = (): IDelivery => {
    return {
      restId: '62908c80859c0b034f521870',
      courier: 0,
      freeDeliverySum: 0,
      cashback: 0,
    }
  }
  const [form, setForm] = useState<IDelivery>(DefaultIForm())

  const schema = useMemo<any>(
    () => ({
      freeDeliverySum: {
        condition: form.freeDeliverySum > 0,
        error: `${Translater.ErrorLength[language.slug]}: ${
          Translater.TableTitles.freeDeliverySum[language.slug]
        }`,
      },
      courier: {
        condition: form.courier > 0,
        error: `${Translater.ErrorLength[language.slug]}: ${
          Translater.TableTitles.courier[language.slug]
        }`,
      },
      percents: {
        condition: percents >= 0 && percents <= 100,
        error: `${Translater.ErrorProcent[language.slug]}: ${
          Translater.TableTitles.bonusesToOrders[language.slug]
        }`,
      },
      cashback: {
        condition: form.cashback >= 0 && form.cashback <= 100,
        error: `${Translater.ErrorProcent[language.slug]}: ${
          Translater.TableTitles.cashback[language.slug]
        }`,
      },
      restId: {
        condition: !!form.restId,
        error: `${Translater.ErrorRestoran[language.slug]}`,
      },
    }),
    [form, percents]
  )

  const { errors, validation } = useValidation(schema)

  const Events = {
    inputHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      const name = e.target.name
      const value = e.target.value

      setForm({ ...form, [name]: value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    buttonHandler: () => {
      Callbacks.SaveData()
    },
  }

  const GetRests = async (restId: string) => {
    try {
      const response: any = await request(
        `${config.API}/restaurants/${restId}`,
        'GET',
        null,
        {
          Authorization: `Bearer ${token as string}`,
        }
      )
      setPercents(response.percents)
      setForm((prev) => ({ ...prev, cashback: response.cashback }))
    } catch (e) {}
  }

  const Callbacks = {
    Fetch: async (restId: string) => {
      try {
        const response: any = await request(
          `${config.API}/delivery/${restId}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )
        setForm({
          ...form,
          courier: response.courier,
          freeDeliverySum: response.freeDeliverySum,
          cashback: response.cashback,
        })
      } catch (e) {}
    },

    SaveData: async () => {
      try {
        await validation()

        const { freeDeliverySum, courier, cashback } = form

        const data = {
          freeDeliverySum: +freeDeliverySum,
          courier: +courier,
          cashback: +cashback,
        }

        await request(
          `${config.API}/restaurants/${form.restId}/percents`,
          'PATCH',
          { percents: +percents, cashback: +cashback },
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        await request(`${config.API}/delivery/${form.restId}`, 'POST', data, {
          Authorization: `Bearer ${token as string}`,
        })
      } catch (e) {
        toogleIsAlertOpen(errors.length > 0)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch(form.restId)
  }, [form.restId])

  useEffect(() => {
    GetRests(form.restId)
  }, [form.restId])

  if (loading) return <Page.Preloader />

  return (
    <Page.Wrapper title={Translater.DeliveryPage.title[language.slug]}>
      <Page.Header
        title={Translater.DeliveryPage.title[language.slug]}
        btnTitle={Translater.Buttons.save[language.slug]}
        buttonHandler={Events.buttonHandler}
      />
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.restaurantsList[language.slug]}
      >
        <ListRestaurants
          selectHandler={Events.selectHandler}
          restaurantsList={form.restId}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.freeDeliverySum[language.slug]}
      >
        <Form.Input
          name='freeDeliverySum'
          value={form.freeDeliverySum}
          inputHandler={Events.inputHandler}
          type='number'
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.cashback[language.slug]}
      >
        <Form.Input
          value={form.cashback}
          type='number'
          name='cashback'
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.courier[language.slug]}
      >
        <Form.Input
          name='courier'
          value={form.courier}
          inputHandler={Events.inputHandler}
          type='number'
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.bonusesToOrders[language.slug]}
      >
        <Form.Input
          value={percents}
          type='number'
          name='percents'
          inputHandler={(e) => setPercents(+e.target.value)}
        />
      </Form.LabelField>

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default IndexPage
