import React, { useState } from 'react'
import { Snackbar } from '@material-ui/core'

const Disconnected: React.FC = () => {

  const [isVisible, toggleVisible] = useState<boolean>(true)

  if (!isVisible) return null

  return (
    <Snackbar
      open={isVisible}
      autoHideDuration={10000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={() => toggleVisible(false)}
    >
      <div></div>
    </Snackbar>
  )
}

export default Disconnected
