import { ITableTitle } from "./../../../../types/table";

export const SHomeTitles: ITableTitle[] = [
  { type: "header", slug: "increment", sort: true },
  { type: "header", slug: "user", sort: true },
  { type: "header", slug: "delivery", sort: true },
  { type: "header", slug: "place", sort: true },
  { type: "header", slug: "restaurant", sort: true },
  { type: "header", slug: "orderStatus", sort: true },
  { type: "header", slug: "itemsPriceBonusses", sort: true },
  { type: "header", slug: "paymentType", sort: true },
  { type: "header", slug: "paymentStatus", sort: true },
  { type: "header", slug: "createdAt", sort: true },
  { type: "header", slug: "" },
];
