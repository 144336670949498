import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Buttons from "../../components/Buttons"
import * as Components from "./components"

import { useHttp } from "../../hooks/http.hook"
import { config, Translater } from "../../config"

import { IStock } from "../../types/items"
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from "../../types/multiLang"

import { useHistory } from "react-router-dom"
import { useValidation } from "../../hooks"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { multiLang } = useContext(Contexts.ConfigContext)

  const { loading, request } = useHttp()
  const history = useHistory()

  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    percent: 0,
    description: false,
    restaurantsList: true,
  })

  const [isClicked, setClick] = useState(false)

  const [form, setForm] = useState<IStock>({
    name: "",
    photo: "",
    percent: 0,
    description: "",
    status: false,
    dateStart: 0,
    dateEnd: 0,
    timeEnd: 0,
    productsList: "",
    products: [],
    categoriesList: "",
    restaurantsList: "",
    infinite: false,
  })
  const [categoriesList, setCategoriesList] = useState<string[]>([])
  const [restaurantsList, setRestaurantsList] = useState<string[]>([])
  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  })
  useEffect(() => {
    console.log("form", form)
  }, [form])

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<any>(
    () => ({
      name: {
        condition: form.name.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },
      photo: {
        condition: !!form.photo,
        error: `
        ${Translater.ErrorPhoto[language.slug]}`,
      },
      description: {
        condition: form.description.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.description[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.type === "file")
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split("[")[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.name == "categoriesList") {
        setCategoriesList(categoriesList.concat(e.target.value))
      }
      setRestaurantsList(restaurantsList.concat(form.restaurantsList))
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    createHandler: async () => {
      try {
        setClick(true)
        await validation()

        Callbacks.Create()
      } catch (e) {
        toogleIsAlertOpen(true)
      }
    },
    setProducts: (products: string[]) => {
      setForm({ ...form, products })
    },
  }

  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData()
        console.log("WORK", form.photo)
        data.append("photo", form.photo)
        data.append("name", form.name)
        data.append("description", form.description)
        data.append("percent", form.percent as string)
        data.append("infinite", form.infinite ? "true" : "false")
        data.append(
          "dateStart",
          !form.infinite ? (form.dateStart as string) : "2023-01-01"
        )
        data.append(
          "dateEnd",
          !form.infinite ? (form.dateEnd as string) : "2023-01-01"
        )
        for (let item of form.productsList) {
          data.append("productsList[]", item)
        }
        console.log(restaurantsList)
        for (let item of new Set(restaurantsList)) {
          item && data.append("restaurantsList[]", item)
        }
        // data.append("restaurantsList[0]", form.restaurantsList as any);

        const response = await request(
          `${config.API}/stocks?infinite=${
            form.dateEnd && form.dateStart ? "true" : "false"
          }`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          history.goBack()
          setForm({
            ...form,
            name: "",
            description: "",
            photo: "",
            percent: 0,
            status: false,
            dateStart: 0,
            dateEnd: 0,
            timeEnd: 0,
            productsList: "",
            categoriesList: "",
            restaurantsList: "",
          })
        }
      } catch (e) {}
    },
  }

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangTitles) {
        if (
          multiLangTitles[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, name: true, restaurantsList: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, name: false, restaurantsList: false })
        }
      }
    } else {
      if (form.name.length >= 2)
        toggleValid({ ...isValid, name: true, restaurantsList: true })
      else toggleValid({ ...isValid, name: false, restaurantsList: false })
    }
  }, [form.name, form.productsList, multiLangTitles])

  useEffect(() => {
    if (form.photo) toggleValid({ ...isValid, photo: true })
    else toggleValid({ ...isValid, photo: false })
  }, [form.photo])

  return (
    <Page.Wrapper title={Translater.StocksCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.StocksCreatePage.title[language.slug]}
        backButtonLink="/stocks"
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />
      <Components.ConfigBlock
        form={form}
        from={"create"}
        //@ts-ignore
        isValid={validationSchema}
        multiLangValues={multiLangTitles}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        setForm={setForm}
        setProducts={Events.setProducts}
        isClicked={isClicked}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
