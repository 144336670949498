import React, { Fragment, useState, useEffect, useContext } from 'react'

import * as Contexts from '../../../contexts'
import * as Form from '../../../components/Form'
import * as Page from '../../../components/Page'

import { Translater } from '../../../config'
import { useHttp } from '../../../hooks'
import { IConfigBlockProps } from './types'
import { IUser } from '../../../types/items'
import { config } from '../../../config'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  isValid,
  setForm,
  isDetailPage = false,
  isClicked,
}) => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()
  const [users, setUsers] = useState<IUser[]>([])

  const Events = {
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const value = e.target.value
      setForm({ ...form, [name]: value })
    },

    selectHandler: (user: any) => {
      setForm({ ...form, user: user._id })
    },
  }

  const Callbacks = {
    getUsers: async () => {
      try {
        const users: IUser[] = await request(
          `${config.API}/users?perPage=1000`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        const Arr = []

        for (let user of users) {
          Arr.push({ ...user, name: `${user.name} +${user.phone}` })
        }

        setUsers(Arr)
      } catch (e) {}
    },
  }

  const Funcs = {
    getDefaultUser: () => {
      let user: any
      if (isDetailPage) {
        user = {
          label: (form.user as IUser)?.name,
          value: (form.user as IUser)?._id,
        }
      } else {
        user = null
      }
      return user
    },
  }

  useEffect(() => {
    Callbacks.getUsers()
  }, [])

  return (
    <Fragment>
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.sum[language.slug]}
      >
        <Form.Input
          name='sum'
          type={'number'}
          value={form.sum}
          isValid={isClicked ? +form.sum > 0 : true}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.user[language.slug]}
      >
        <Page.SearchSelect
          data={users}
          placeholder={Translater.TableTitles.usersSearch[language.slug]}
          searchField='_id'
          dataField='name'
          setSwitch={Events.selectHandler}
          value={Funcs.getDefaultUser()}
        />
      </Form.LabelField>
    </Fragment>
  )
}

export default ConfigBlock
