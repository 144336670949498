import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import deleteIcon from "../../assets/icons/delete.png"

import { useHttp, useValidation } from "../../hooks"

import { config, Translater } from "../../config"

import { IPagination } from "../../types/items"
import * as Form from "../../components/Form"
import { TCreateValidationSchema } from "./types"
import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const [pagination, setPagination] = useState<IPagination>({
    totalCount: 0,
    page: 1,
    perPage: 10,
  })
  const { loading, request, totalCount } = useHttp()

  const [primary, setPrimary] = useState<Array<any>>([])
  const [form, setForm] = useState<any>({
    title: "",
    body: "",
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
  const [isClicked, setClick] = useState(false)

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      title: {
        condition: form.title.length >= 3,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.title[language.slug]}`,
      },
      body: {
        condition: form.body.length >= 3 && form.body.length <= 300,
        error: ` ${Translater.MinMax[language.slug]}:
        ${Translater.TableTitles.message[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )

  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      const name = e.target.name
      const value = e.target.value

      setForm({ ...form, [name]: value })
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    },
    saveHandler: async () => {
      try {
        setClick(true)
        await validation()
        Callbacks.SaveData()
        setForm({ title: "", body: "" })
      } catch (error) {
        toogleIsAlertOpen(true)
        console.log("error", error)
      }
    },

    paginateSelectHandler: (e: any) => {
      setPagination({ ...pagination, page: 1, perPage: e.target.value })
    },

    paginateHandler: (page: any) => {
      setPagination({ ...pagination, page })
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: any = await request(
          `${config.API}/notifications/?page=${pagination.page}&perPage=${pagination.perPage}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )

        if (response) {
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      }
    },

    SaveData: async () => {
      try {
        await request(`${config.API}/users/sendNotifications`, "POST", form, {
          Authorization: `Bearer ${token as string}`,
        })

        window.location.reload()
      } catch (e) {
        console.log(e)
      }
    },
  }

  const deleteNotificationHandler = async (id: string) => {
    try {
      await request(`${config.API}/notifications/${id}`, "DELETE", null, {
        Authorization: `Bearer ${token as string}`,
      })
      Callbacks.Fetch()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [pagination])

  useEffect(() => {
    console.log(totalCount)
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper title={Translater.NotificationsPage.title[language.slug]}>
      <Page.Header
        title={Translater.NotificationsPage.title[language.slug]}
        btnTitle={Translater.Buttons.send[language.slug]}
        buttonHandler={Events.saveHandler}
      />

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.slug[language.slug]}
        text={form.percents}
      >
        {
          <Form.Input
            name="title"
            placeholder={Translater.Placeholders.minThree[language.slug]}
            isValid={isClicked ? validationSchema.title : true}
            value={form.title}
            inputHandler={Events.inputHandler}
          />
        }
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.message[language.slug]}
        text={form.message}
      >
        {
          <Form.Textarea
            name="body"
            placeholder={Translater.Placeholders.minThree[language.slug]}
            isValid={isClicked ? validationSchema.body : true}
            value={form.body}
            textareaHandler={Events.inputHandler}
            maxLength={300}
          />
        }
      </Form.LabelField>

      <Page.SortBar
        perPage={pagination.perPage}
        selectHandler={Events.paginateSelectHandler}
      />

      <div style={{ marginBottom: 120 }}>
        {primary?.map((item: any) => {
          return (
            <div>
              <div key={item._id} className="page-notification-list">
                <div>
                  <div className="page-notification-title">{item.title}</div>{" "}
                  <div className="page-notification-body">{item.body}</div>{" "}
                </div>
                <img
                  onClick={() => deleteNotificationHandler(item?._id)}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  src={deleteIcon}
                />
              </div>
            </div>
          )
        })}
      </div>

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>

      <Page.Footer>
        <Page.Pagination
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          linkTo="/notifications"
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default IndexPage
