import React, { useEffect, useState } from "react"

import * as Table from "../../../../components/Table"
import * as Components from ".."

import { ITableWrapperProps } from "./types"

import { SHomeTitles } from "./static"
import { SortableList } from "./components"

const TableWrapper: React.FC<ITableWrapperProps> = ({
  items,
  reloadHandler,
  sortHandler,
  setPrimary,
}) => {
  const [categories, setItems] = useState(
    items.map((item) => ({
      id: item._id as string,
      _id: item._id as string,
      title: item.title,
      photo: item.photo,
      restaurant: item.restaurant,
      parent_id: item.parent_id,
      hidden: item.hidden,
      promotionalOffer: item.promotionalOffer,
      position: item.position,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      products: item.products,
    }))
  )

  useEffect(() => {
    setPrimary && setPrimary(categories)
  }, [categories])

  return (
    <Table.Table
      type="categories"
      titles={SHomeTitles}
      sortHandler={sortHandler}
    >
      <SortableList
        items={categories}
        onChange={setItems}
        renderItem={(item) => (
          <SortableList.Item id={item.id}>
            <Components.Track
              key={item.id}
              data={item}
              nesting={0}
              reloadHandler={reloadHandler}
            />
          </SortableList.Item>
        )}
      />
    </Table.Table>
  )
}

export default TableWrapper
