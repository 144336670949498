import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Components from "./components"
import * as Buttons from "../../components/Buttons"

import { useHttp } from "../../hooks/http.hook"
import { ICategory } from "../../types/items"
import { config, Translater } from "../../config"
import { useParams, useHistory } from "react-router-dom"
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from "../../types/multiLang"
import { TDetailValidationSchema } from "./types"
import { useValidation } from "../../hooks"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const history = useHistory()
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<ICategory | null>(null)
  const [isValid, toggleValid] = useState({
    title: false,
    photo: true,
    position: true,
    restaurants: true,
  })
  const [form, setForm] = useState<ICategory>({
    title: "",
    hidden: "",
    promotionalOffer: "",
    parent_id: "",
    restaurant: [],
    photo: "",
    position: "",
    products: [],
  })
  const [multiLangValues, setMultiLangValues] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      title: {
        condition: form.title.length >= 2,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      position: {
        condition: !!form.position && !!parseInt(form.position),
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.position[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )
  const { errors, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.type === "file")
        //@ts-ignore
        setForm({ ...form, photo: e.target.files[0] })
      else if (e.target.name.split("[")[1]) {
        setMultiLangValues({
          ...multiLangValues,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    setParentID: (parent_id: string) => {
      setForm({ ...form, parent_id })
    },
    setRestaurant: (restaurant: string[]) => {
      setForm({ ...form, restaurant })
    },
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    SaveProducts: async () => {
      form.products?.forEach(async (product) => {
        try {
          const data = new FormData()
          data.append("name", product.name)

          data.append("description", product.description)
          data.append("isVariated", product.isVariated ? "true" : "false")
          data.append("novelty", product.novelty ? "true" : "false")

          product.order && data.append("order", product.order.toString())

          await request(
            `${config.API}/products/${product?._id}`,
            "POST",
            data,
            {
              Authorization: `Bearer ${token as string}`,
            }
          )
        } catch (e) {}
      })
    },
    Fetch: async () => {
      try {
        const response: ICategory = await request(
          `${config.API}/categories/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        if (response) {
          setPrimary(response)
        }
      } catch (e) {}
    },
    FetchCategory: async () => {
      try {
        const response: ICategory = await request(
          `${config.API}/categories/${primary?.parent_id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        if (primary) setPrimary({ ...primary, parentCategory: response })
      } catch (e) {
        console.log(e)
      }
    },
    Save: async () => {
      try {
        const data = new FormData()

        if (multiLang) {
          for (let key in multiLangValues) {
            data.append(key, multiLangValues[key as TPossibleMultiLangTitle])
          }
        } else data.append("title", form.title)
        data.append("position", form.position as string)
        console.log("SENDFORM", form)

        form.photo && data.append("photo", form.photo)
        data.append("hidden", form.hidden as string)
        data.append("promotionalOffer", form.promotionalOffer as string)
        if (form.restaurant)
          for (let rest of form.restaurant) {
            // @ts-ignore
            data.append("restaurant[]", rest._id ? rest._id : rest)
          }
        data.append("parent_id", form.parent_id || "")
        const response = await request(
          `${config.API}/categories/${primary?._id}`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        response && (await Callbacks.SaveProducts())
        // if (response) history.push("/categories")
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/categories/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        })

        history.push("/categories")
      } catch (e) {}
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (primary)
      setForm({
        ...form,
        title: primary.title,
        hidden: primary.hidden,
        promotionalOffer: primary.promotionalOffer,
        parent_id: primary.parent_id,
        restaurant: primary.restaurant,
        position: primary.position,
      })
  }, [primary])

  console.log(primary, "primary")

  useEffect(() => {
    if (primary?.parent_id) Callbacks.FetchCategory()
  }, [primary?.parent_id])

  useEffect(() => {
    if (multiLang) {
      let isRight = true

      for (let key in multiLangValues) {
        if (
          multiLangValues[key as TPossibleMultiLangTitle].length >= 2 &&
          isRight
        ) {
          toggleValid({ ...isValid, title: true })
          isRight = true
        } else {
          isRight = false
          toggleValid({ ...isValid, title: false })
        }
      }
    } else {
      if (form.title.length >= 2) toggleValid({ ...isValid, title: true })
      else toggleValid({ ...isValid, title: false })
    }
  }, [form.title, multiLangValues])

  if (loading || !primary) return <Page.Preloader />
  return (
    <Page.Wrapper title={primary.title}>
      <Page.Header
        backButtonTitle={Translater.CategoriesDetailPage.title[language.slug]}
        backButtonLink="/categories"
      />
      <Components.ConfigBlock
        data={primary}
        form={form}
        isValid={isValid}
        multiLangValues={multiLangValues}
        setForm={setForm}
        setParentID={Events.setParentID}
        inputHandler={Events.inputHandler}
        isDetailPage
        selectHandler={Events.selectHandler}
        setRestaurant={Events.setRestaurant}
      />

      <Components.DetailBlock data={primary} form={form} setForm={setForm} />
      <Buttons.Container
        disabled={loading}
        position={"relative"}
        saveHandler={access.categories?.change ? Events.saveHandler : false}
        deleteHandler={access.categories?.delete ? Events.deleteHandler : false}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default DetailPage
