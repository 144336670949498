import React, { useContext, useEffect, useState } from "react"
import * as Form from "../../../../components/Form"
import { Translater } from "../../../../config"
import * as Contexts from "../../../../contexts"
import { TProductsDropableList } from "./types"
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"
import { IProduct } from "../../../../types/items"

export const ProducstDropableList: React.FC<TProductsDropableList> = ({
  products,
  setForm,
  form,
}) => {
  const { language } = useContext(Contexts.LanguageContext)
  const [productsOrder, setProductsOrder] = useState<IProduct[]>(
    products ? products : []
  )

  const Events = {
    onDragEnd: (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      let items = Utils.reorder(
        productsOrder,
        result.source.index,
        result.destination.index
      )

      items = items.map((item, order) => ({ ...item, order: order + 1 }))

      setProductsOrder([...items])
    },
  }

  const Utils = {
    // a little function to help us with reordering the result
    reorder: (list: any[], startIndex: number, endIndex: number) => {
      const result = list
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)

      return result
    },
  }

  useEffect(() => {
    setForm && setForm({ ...form, products: productsOrder })
  }, [productsOrder])

  useEffect(() => {
    if (products?.length) {
      let arr = products
      if (arr[0].order) {
        arr = arr.sort((a, b) => {
          if (a.order && b.order) {
            if (a.order > b.order) {
              return 1
            }

            if (a.order < b.order) {
              return -1
            }
          }

          return 0
        })
      }

      setProductsOrder(arr)
    }
  }, [])

  return (
    <Form.LabelField
      fontSize="small"
      label={Translater.TableTitles.products[language.slug]}
    >
      <DragDropContext onDragEnd={Events.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {productsOrder &&
                productsOrder.map((product, index) => (
                  <Draggable
                    key={product._id as string}
                    draggableId={product._id as string}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={"dragCard"}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {product.name}
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Form.LabelField>
  )
}
