import React, { useContext } from "react"

import * as Contexts from "../../../contexts"
import * as Table from "../../../components/Table"

import { useHistory } from "react-router-dom"
import { useHttp } from "../../../hooks/http.hook"
import { config, Translater } from "../../../config"
import { ITrackProps } from "./types"

const Track: React.FC<ITrackProps> = ({ data, type, order, reloadHandler }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()
  const history = useHistory()

  const Events = {
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
    editHandler: () => {
      history.push(`/feedback/${data._id}`)
    },
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(
          `${config.API}/products/feedback/${data._id}`,
          "DELETE",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        await reloadHandler()
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <Table.Track type={type !== "base" ? "feedback_brief" : "feedback"}>
      <Table.Cell text={`${order}`} />

      {type === "base" || type === "user" ? (
        <Table.Cell
          linkText={data.product.name}
          linkTo={`/products/${data.product._id}`}
        />
      ) : null}

      {type === "base" || type === "product" ? (
        <Table.Cell
          linkText={data.user.name}
          linkTo={`/users/${data.user._id}`}
        />
      ) : null}

      <Table.Cell text={`${data.rate}`} />

      <Table.Cell
        text={
          data.comment
            ? data.comment
            : Translater.TableTitles.withoutComment[language.slug]
        }
        modalContent={
          <Table.ModalContent
            text={
              data.comment
                ? data.comment
                : Translater.TableTitles.withoutComment[language.slug]
            }
          />
        }
      />

      <Table.Cell
        text={new Date(data.updatedAt as string).toLocaleDateString()}
      />

      <Table.Cell
        type="control"
        editButton={access.products?.change}
        deleteButton={access.products?.delete}
        editHandler={Events.editHandler}
        deleteHandler={Events.deleteHandler}
      />
    </Table.Track>
  )
}

export default Track
