import { ITableTitle } from "../../../../types/table"

export const STitles: ITableTitle[] = [
  { type: "header", slug: "increment", sort: true },
  { type: "header", slug: "photo" },
  { type: "header", slug: "title", sort: true },
  { type: "header", slug: "description" },
  { type: "header", slug: "restoraunt" },
  { type: "header", slug: "updatedAt", sort: true },
]
