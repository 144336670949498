import React, { Fragment, useState, useEffect, useContext } from "react"

import * as Contexts from "../../../contexts"
import * as Buttons from "../../../components/Buttons"
import * as Form from "../../../components/Form"
import * as Page from "../../../components/Page"
import * as Utils from "../../../utils"

import { config, Translater } from "../../../config"
import { useHttp } from "../../../hooks"

import { SHiddenOptions } from "../../../components/Form/static"
import {
  IAdditions,
  ICategory,
  IProduct,
  IRestaurant,
  IVariations,
} from "../../../types/items"
import { IConfigBlockProps } from "./types"
import { DefaultButton } from "../../../components/Buttons"

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  showPhoto = false,
  multiLangTitles,
  multiLangDescriptions,
  setCategory,
  inputHandler,
  selectHandler,
  setForm,
  textareaHandler,
  isDetailPage = false,
  setRestaurant,
  isClicked,
}) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request, totalCount } = useHttp()
  const [weight, setWeight] = useState<string>()
  const [price, setPrice] = useState<string>()

  const [showValue, setShowValue] = useState<boolean>(false)
  const [, setInputValue] = useState<string>("")
  const [, setInputRestaurantValue] = useState<string>("")
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [restaurantOptions, setRestaurantOptions] = useState<any[]>([])
  const [children, setChildren] = useState<any[]>([])
  const [, setCategorySelectValue] = useState<any>({
    label: "",
    value: "",
  })
  const [, setRestaurantSelectValue] = useState<any>({
    label: "",
    value: "",
  })
  const [adds, setAdds] = useState<IAdditions[]>([])
  const [ad, setAd] = useState<any>({})
  const [rest, setRest] = useState<any>({})
  const [vars, setVars] = useState<IVariations>({
    name: "",
    priceChange: "",
    weightChange: "",
  })
  const [searchSelAds, setSearchSelAds] = useState<string>("")

  const Callbacks = {
    loadAdditionsById: async (
      id = form.restaurant[0] ? form.restaurant[0] : "0"
    ) => {
      try {
        const adds = await request(
          `${config.API}/addition?perPage=100`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        setAdds(adds)
      } catch (e) {
        console.log(e)
      }
    },
  }

  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value)
    },
    inputRestaurantHandler: (value: string) => {
      setInputRestaurantValue(value)
    },
    loadChildrenOptions: async () => {
      try {
        if (!!rest) {
          const response: IProduct[] = await request(
            `${config.API}/products?perPage=1000`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            }
          )

          setChildren(response)
        }
      } catch (e) {
        console.log(e)
      }
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        if (response && form._id) {
          setForm && setForm({ ...form, restaurant: [response[0]._id] })
        }
        setRestaurantOptions(response)
      } catch (e) {}
    },
    loadCategoriesOptions: async () => {
      try {
        const response: ICategory[] = await request(
          `${config.API}/categories?perPage=50`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        setCategoryOptions(response)
      } catch (e) {
        console.log(e)
      }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setCategory(option.value)
    },
    optionRestaurantHandler: (option: any) => {
      setRestaurantSelectValue(option)
      setRestaurant && setRestaurant(option)
    },
    buttonHandler: () => {
      setShowValue(!showValue)
    },

    arrowsHandler: (idx: number, old: number) => {
      const variations = Utils.changeArrayItemPosition(
        idx,
        old,
        form.variations
      )
      setForm && setForm({ ...form, variations })
    },
    selectHandler: (e: any) => {
      console.log("E", e.target.value)
    },
  }

  useEffect(() => {
    Events.loadRestaurantOptions()
  }, [])

  useEffect(() => {
    Events.loadChildrenOptions()
  }, [rest])

  useEffect(() => {
    Events.loadCategoriesOptions()
  }, [])

  useEffect(() => {
    if (data?.isVariated && data.variations.length) {
      setPrice(data.variations[0].priceChange)
    }
  }, [])

  useEffect(() => {
    if (
      data?.isVariated &&
      data.variations.length &&
      data.variations[0].weightChange
    ) {
      setWeight(data.variations[0].weightChange)
    }
  }, [])

  useEffect(() => {
    if (form.restaurant) {
      Callbacks.loadAdditionsById()
    } else {
      Callbacks.loadAdditionsById(rest._id)
    }
  }, [rest, form.restaurant])

  const adsSelectHandler = (ad: any) => {
    setAd(ad)
  }

  const addOptionHandler = () => {
    if (Object.keys(ad).length) {
      const additions = form.additions && [
        ...form.additions.filter((i) => i._id != ad._id),
        ad,
      ]
      setForm && setForm({ ...form, additions })
    }
  }

  const deleteAd = (id: string) => {
    if (id != null) {
      setForm &&
        setForm({
          ...form,
          additions: form.additions?.filter((i) => i._id !== id),
        })
    } else {
      setForm &&
        setForm({
          ...form,
          additions: form.additions?.filter((i) => i != null),
        })
    }
  }

  const deleteVar = (variation: string) => {
    if (variation != null) {
      if (variation) {
        setForm &&
          setForm({
            ...form,
            variations: form.variations.filter((i) => i.name != variation),
          })
      }
    } else {
      setForm &&
        setForm({
          ...form,
          variations: form.variations?.filter((i) => i != null),
        })
    }
  }

  const addVar = () => {
    if (
      !form.variations.find((i) => i.name == vars.name) &&
      vars.name.length > 0 &&
      vars.priceChange.length > 0
    ) {
      const newVar =
        vars.weightChange && vars.weightChange.length > 0
          ? vars
          : { priceChange: vars.priceChange, name: vars.name }
      setForm && setForm({ ...form, variations: [...form.variations, newVar] })
      setVars({ name: "", priceChange: "", weightChange: "" })
    }
  }

  const varsHandler = (e: any) => {
    const name = e.target.name
    const value = e.target.value
    setVars({ ...vars, [name]: value })
  }

  useEffect(() => {
    form.isVariated == "true" &&
      setForm &&
      setForm({ ...form, price: 0, weight: "0" })
  }, [form.isVariated])

  useEffect(() => {
    if (typeof form.isVariated == "boolean") {
      if (form.isVariated) {
        setForm && setForm({ ...form, isVariated: "true" })
      } else {
        setForm && setForm({ ...form, isVariated: "false" })
      }
    }
  })

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          name="name"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.name.length > 0 : true}
          value={form.name}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.description[language.slug]}
      >
        <Form.Textarea
          name="description"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.description.length > 0 : true}
          value={form.description}
          textareaHandler={textareaHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.hasVariations[language.slug]}
      >
        <Form.Select
          name="isVariated"
          options={SHiddenOptions}
          value={form.isVariated as string}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      {form.isVariated != "true" ? (
        <>
          <Form.LabelField
            fontSize="small"
            label={Translater.TableTitles.weight[language.slug]}
          >
            <Form.Input
              name="weight"
              type="number"
              placeholder={Translater.Placeholders.minTwo[language.slug]}
              isValid={isClicked ? form.weight.length > 0 : true}
              value={form.weight}
              inputHandler={inputHandler}
            />
          </Form.LabelField>

          <Form.LabelField
            fontSize="small"
            label={Translater.TableTitles.price[language.slug]}
          >
            <Form.Input
              type="number"
              name="price"
              value={form.price}
              isValid={isClicked ? form.price.toString().length > 0 : true}
              inputHandler={inputHandler}
            />
          </Form.LabelField>
        </>
      ) : (
        <>
          {form.variations.map((i, idx) => (
            <Page.Item
              deleteField="name"
              idx={idx}
              key={idx}
              data={{
                ...i,
                priceChange: `${i.priceChange}грн`,
                weightChange: i.weightChange ? `${i.weightChange}г` : undefined,
              }}
              deleteHandler={deleteVar}
              fields={["name", "priceChange", "weightChange"]}
              arrows
              arrowsHandler={Events.arrowsHandler}
            />
          ))}

          <div>
            <div style={{ marginTop: 20 }}>
              <Form.LabelField
                fontSize="small"
                label={Translater.Variants.property[language.slug]}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.name}
                  name="name"
                />
              </Form.LabelField>

              <Form.LabelField
                fontSize="small"
                label={Translater.TableTitles.weight[language.slug]}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.weightChange}
                  type="number"
                  name="weightChange"
                />
              </Form.LabelField>

              <Form.LabelField
                fontSize="small"
                label={Translater.TableTitles.price[language.slug]}
              >
                <Form.Input
                  inputHandler={varsHandler}
                  value={vars.priceChange}
                  type="number"
                  name="priceChange"
                />
              </Form.LabelField>

              <DefaultButton
                title={Translater.Buttons.add[language.slug]}
                buttonHandler={addVar}
              />
            </div>
          </div>
        </>
      )}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.hidden[language.slug]}
      >
        <Form.Select
          name="hidden"
          options={SHiddenOptions}
          value={form.hidden as string}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.novelty[language.slug]}
      >
        <Form.Select
          name="novelty"
          options={SHiddenOptions}
          value={form.novelty as string}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.restaurants[language.slug]}
      >
        <Fragment>
          <Page.SearchSelect
            isMulti
            placeholder={Translater.Placeholders.searchByRests[language.slug]}
            searchField="title"
            data={restaurantOptions}
            setSwitch={Events.optionRestaurantHandler}
            multiField="_id"
            value={isDetailPage ? form.restaurant : null}
            isInvalid={
              isClicked
                ? !(form.restaurant.length > 1 || form.restaurant.length == 1)
                : false
            }
          />
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.category[language.slug]}
      >
        <Fragment>
          <>
            <Page.SearchSelect
              placeholder={Translater.Placeholders.category[language.slug]}
              data={categoryOptions}
              searchField="title"
              setSwitch={(category: any) =>
                setForm && setForm({ ...form, category })
              }
              value={
                isDetailPage
                  ? { label: form?.category?.title, title: form?.category?._id }
                  : null
              }
            />
          </>
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.parentProduct[language.slug]}
      >
        <Fragment>
          <>
            <Form.Dropdown
              disabledValue={
                Translater.Placeholders.parentProduct[language.slug]
              }
              isOption={true}
              items={children.filter((item) => item._id != form._id)}
              searchField="name"
              item={form.parent}
              name="parent"
              changeHandler={selectHandler}
            />
          </>
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <Form.Input type="file" name="photo" inputHandler={inputHandler} />
      </Form.LabelField>

      {showPhoto ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.photo[language.slug]}
        >
          {data?.photo !== null ? (
            <img
              alt="Product image"
              src={`${config.imageProxy}${(data as any).photo}`}
              style={{ objectFit: "cover", width: 350, height: 250 }}
            />
          ) : (
            <img alt="Product image" />
          )}
        </Form.LabelField>
      ) : null}

      {form.additions?.map((i, idx) => (
        <Page.Item
          data={i}
          fields={["name", "price", "weight"]}
          idx={idx}
          key={idx}
          deleteHandler={deleteAd}
          deleteField="_id"
        />
      ))}
      {showValue && adds ? (
        <div>
          {adds && (
            <div className="adds">
              <Page.SearchSelect
                placeholder={
                  Translater.Placeholders.searchAddition[language.slug]
                }
                data={adds}
                searchField="name"
                setSwitch={adsSelectHandler}
                value={searchSelAds}
                setValue={setSearchSelAds}
              />
              <DefaultButton
                title={Translater.Buttons.add[language.slug]}
                buttonHandler={addOptionHandler}
              />
            </div>
          )}
        </div>
      ) : null}

      {access.products?.change && (
        <DefaultButton
          title={Translater.Buttons.addOptions[language.slug]}
          buttonHandler={Events.buttonHandler}
        />
      )}
    </Fragment>
  )
}

export default ConfigBlock
