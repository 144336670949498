import React from 'react'

import { ITableWrapperProps } from './type'

const Track: React.FC<ITableWrapperProps> = ({
  type,
  children,
  clickHandler = () => {},
  backgroundColor = '',
  tableTrack = true,
}) => {
  return (
    <div
      className={`${
        tableTrack && 'table-track'
      } table--grid table--${type} ${backgroundColor}`}
      style={{ backgroundColor }}
      onClick={clickHandler}
    >
      {children}
    </div>
  )
}

export default Track
