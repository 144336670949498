import { ITableTitle } from "../../../types/table"

export const SBaseTitles: ITableTitle[] = [
  { type: "header", slug: "increment" },
  { type: "header", slug: "product", sort: true },
  { type: "header", slug: "user", sort: true },
  { type: "header", slug: "rate", sort: true },
  { type: "header", slug: "comment", sort: true },
  { type: "header", slug: "updatedAt", sort: true },
  { type: "header", slug: "" },
]

export const SProductTitles: ITableTitle[] = [
  { type: "header", slug: "increment" },
  { type: "header", slug: "product" },
  { type: "header", slug: "rate", sort: true },
  { type: "header", slug: "comment", sort: true },
  { type: "header", slug: "updatedAt", sort: true },
  { type: "header", slug: "" },
]

export const SUserTitles: ITableTitle[] = [
  { type: "header", slug: "increment" },
  { type: "header", slug: "user" },
  { type: "header", slug: "rate", sort: true },
  { type: "header", slug: "comment", sort: true },
  { type: "header", slug: "updatedAt", sort: true },
  { type: "header", slug: "" },
]
