import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  Fragment,
  useMemo,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Form from "../../components/Form"
import * as Components from "./components"
import * as Buttons from "../../components/Buttons"

import { useHttp } from "../../hooks"
import { IProduct, IOptions, IAdditions } from "../../types/items"
import { config, Translater } from "../../config"
import { useParams, useHistory } from "react-router-dom"
import {
  TMutliLangTitles,
  TMutliLangDescriptions,
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions,
} from "../../types/multiLang"
import { useValidation } from "../../hooks"
import { TDetailValidationSchema } from "./types"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)
  const [defaultAdditions, setDefaultAdditions] = useState<IAdditions[]>([])

  const history = useHistory()
  const { id } = useParams() as any
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IProduct | null>(null)
  const [isValid, toggleValid] = useState({
    name: true,
    category: true,
    description: true,
    weight: true,
    restaurant: true,
  })

  const [form, setForm] = useState<IProduct>({
    name: "",
    price: "",
    photo: "",
    parent: "",
    hidden: "",
    category: "",
    restaurant: [],
    description: "",
    options: [],
    weight: "",
    novelty: "false",
    additions: [],
    variations: [],
    isVariated: false,
  })
  const [optionValue, setOptionValue] = useState<any>({
    option: "",
    photo_option: "",
    price_option: "0",
    additions: "",
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      name: {
        condition: form.name.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.title[language.slug]}`,
      },
      description: {
        condition: form.description.length >= 2,
        error: ` ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.description[language.slug]}`,
      },
      category: {
        condition: !!form.category,
        error: ` ${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.category[language.slug]}
        `,
      },
      variations: {
        condition:
          (((form.isVariated && form.isVariated != "false") ||
            form.isVariated == "true") &&
            form.variations.length) ||
          form.isVariated == "false" ||
          !form.isVariated,
        error: Translater.ErrorVariations[language.slug],
      },
      weight: {
        condition:
          (parseInt(form.weight) >= 0 &&
            (form.isVariated == "false" || !form.isVariated)) ||
          (form.isVariated && form.isVariated != "false") ||
          form.isVariated == "true",
        error: ` ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.weight[language.slug]}`,
      },
      price: {
        condition:
          (form.price > 0 &&
            (form.isVariated == "false" || !form.isVariated)) ||
          (form.isVariated && form.isVariated != "false") ||
          form.isVariated == "true",
        error: ` ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.price[language.slug]}`,
      },
      restaurant: {
        condition: form.restaurant.length > 1 || form.restaurant.length == 1,
        error: `${Translater.ErrorField[language.slug]}: ${
          Translater.ErrorRestaurants[language.slug]
        }`,
      },
    }),
    [form, Translater, language]
  )
  const { errors, validation } = useValidation(schema)

  const [options, setOptions] = useState<IOptions[]>([])

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  })

  const [multiLangDescriptions, setMultiLangDescriptions] =
    useState<TMutliLangDescriptions>({
      "description[EN]": "",
      "description[RU]": "",
      "description[UA]": "",
    })

  const Events = {
    inputOptionHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const type = e.target.type
      const value = e.target.value

      //@ts-ignore
      if (type === "file")
        //@ts-ignore
        setOptionValue({ ...optionValue, photo_option: e.target.files[0] })
      else {
        setOptionValue({ ...optionValue, [name]: value })
      }
    },
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name
      const type = e.target.type
      const value = e.target.value
      //@ts-ignore
      if (type === "file") setForm({ ...form, photo: e.target.files[0] })
      else if (type === "number") {
        if (+value >= 0) setForm({ ...form, [name]: value })
      } else if (name.split("[")[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [name]: value,
        })
      } else setForm({ ...form, [name]: value })
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name.split("[")[1]) {
        setMultiLangDescriptions({
          ...multiLangDescriptions,
          [e.target.name]: e.target.value,
        })
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    buttonOptionHandler: () => {
      setOptions([...options, optionValue])
      setOptionValue({
        option: "",
        photo_option: "",
        price_option: "0",
        additions: "",
      })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      console.log("HANDLER", e.target.name)
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    removeHandler: (id: string) => {
      let similar: IProduct[] = [...(primary?.similar as IProduct[])]

      similar = similar.filter((item) => item._id !== id)

      //@ts-ignore
      setPrimary({ ...primary, similar })
    },
    setCategory: (category: string) => {
      setForm({ ...form, category })
    },
    setRestaurant: (restaurant: string[]) => {
      setForm({ ...form, restaurant })
    },
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },

    deleteOptionHandler: (id: string) => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.DeleteOptions(id)
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IProduct = await request(
          `${config.API}/products/${id}/`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          setDefaultAdditions(response.additions)
          let parent = null
          if (!!response.parent) {
            parent = await request(
              `${config.API}/products/${response.parent}`,
              "GET",
              null,
              {
                Authorization: `Bearer ${token as string}`,
              }
            )
          }
          const formData = {
            ...response,
            parent: parent && parent._id,
          }
          setPrimary(formData)
          setForm(formData)
        }
      } catch (e) {}
    },
    SetSimilar: async (): Promise<boolean> => {
      return !!false
    },
    Save: async () => {
      try {
        const data = new FormData()
        if (multiLang) {
          for (let key in multiLangTitles) {
            data.append(key, multiLangTitles[key as TPossibleMultiLangTitle])
          }
        } else data.append("name", form.name)

        if (multiLang) {
          for (let key in multiLangDescriptions) {
            data.append(
              key,
              multiLangDescriptions[key as TPossibleMultiLangDescriptions]
            )
          }
        } else {
          data.append("description", form.description)
        }

        if (form.photo && typeof form.photo != "string") {
          data.append("photo", form.photo)
        }

        data.append("price", form.price as string)
        data.append("isVariated", form.isVariated as string)

        data.append("hidden", form.hidden as string)
        if (form.category) data.append("category", form.category._id as string)

        if (form.parent) {
          data.append("parent", form.parent)
        }

        data.append("weight", form.weight as string)
        data.append("novelty", form.novelty as string)

        for (let rest of form.restaurant) {
          //@ts-ignore
          data.append("restaurant[]", rest._id ? rest._id : "")
        }

        const addsArray: any[] = []

        // if (form.additions) {
        for (let ad of form.additions) {
          addsArray.concat(ad._id)
          data.append("additions[]", ad?._id)
        }
        // }

        // console.log(addsArray)
        // console.log(form.additions)

        // if (addsArray.length > 0) {
        //   for (let adItem of addsArray) {
        //     data.append("additions[]", adItem)
        //   }
        // }

        if (form.variations.length > 0) {
          for (let varData of form.variations) {
            data.append("variations[]", JSON.stringify(varData))
          }
        }

        await request(`${config.API}/products/${primary?._id}`, "POST", data, {
          Authorization: `Bearer ${token as string}`,
        })

        history.goBack()
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/products/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        })

        history.push("/products")
      } catch (e) {
        console.log(e)
      }
    },

    DeleteOptions: async (id: string) => {
      try {
        await request(`${config.API}/products/${id}`, "PUT", null, {
          Authorization: `Bearer ${token as string}`,
        })

        history.push("/products")
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    const prevIsValid = { ...isValid }

    if (multiLang) {
      let isRightTitles = true

      for (let key in multiLangTitles) {
        if (
          multiLangTitles[key as TPossibleMultiLangTitle].length >= 2 &&
          isRightTitles
        ) {
          isRightTitles = true
          prevIsValid.name = true
        } else {
          isRightTitles = false
          prevIsValid.name = false
        }
      }

      let isRightDesc = true

      for (let key in multiLangDescriptions) {
        if (
          multiLangDescriptions[key as TPossibleMultiLangDescriptions].length >=
            2 &&
          isRightDesc
        ) {
          isRightDesc = true
          prevIsValid.description = true
        } else {
          isRightDesc = false
          prevIsValid.description = false
        }
      }

      toggleValid(prevIsValid)
    } else {
      prevIsValid.name = true
      prevIsValid.description = true

      toggleValid(prevIsValid)
    }
  }, [form.name, form.description, multiLangTitles, multiLangDescriptions])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper footer title={primary.name}>
      <Page.Header
        backButtonTitle={Translater.ProductsDetailPage.title[language.slug]}
        backButtonLink="/products"
      />
      <Components.ConfigBlock
        showPhoto
        form={form}
        setForm={setForm}
        optionValue={optionValue}
        options={options}
        data={primary}
        multiLangTitles={multiLangTitles}
        multiLangDescriptions={multiLangDescriptions}
        setCategory={Events.setCategory}
        setRestaurant={Events.setRestaurant}
        inputHandler={Events.inputHandler}
        inputOptionHandler={Events.inputOptionHandler}
        buttonOptionHandler={Events.buttonOptionHandler}
        selectHandler={Events.selectHandler}
        textareaHandler={Events.textareaHandler}
        defaultAdds={defaultAdditions}
        setDefaultAdds={setDefaultAdditions}
        isDetailPage
      />

      <Form.DateBlock
        updated={primary.updatedAt as string}
        created={primary.createdAt as string}
      />
      <Fragment>
        <Buttons.Container
          disabled={loading}
          position={"relative"}
          deleteHandler={
            access.categories?.delete ? Events.deleteHandler : false
          }
          saveHandler={access.categories?.change ? Events.saveHandler : false}
        />
      </Fragment>

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default DetailPage
