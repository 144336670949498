import { Editor as EditorComponent } from "@tinymce/tinymce-react";
import { TEditorProps } from "./types";
import { config } from "./config";
import React from "react";

export const Editor = ({ initialValue, onEditorChange }: TEditorProps) => {
  return (
    <EditorComponent
      apiKey="lci85rlzi43qr9ohaqaufzdvoefi7w9ldifxcexfwhtn2ff8"
      initialValue={initialValue}
      init={config}
      onEditorChange={onEditorChange}
    />
  );
};
